import React from 'react';
export const Nav30DataSource = {
	wrapper: { className: 'header3 home-page-wrapper' },
	page: { className: 'home-page' },
	logo: {
		className: 'header3-logo',
		children:
			'https://gw.alipayobjects.com/zos/basement_prod/b30cdc2a-d91c-4c78-be9c-7c63b308d4b3.svg',
	},
	Menu: {
		className: 'header3-menu',
		children: [
			{
				name: 'item0',
				className: 'header3-item',
				children: {
					href: '#',
					children: [{ children: '导航一', name: 'text' }],
				},
				subItem: [
					{
						name: 'sub0',
						className: 'item-sub',
						children: {
							className: 'item-sub-item',
							children: [
								{
									name: 'image0',
									className: 'item-image',
									children:
										'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
								},
								{
									name: 'title',
									className: 'item-title',
									children: 'Ant Design',
								},
								{
									name: 'content',
									className: 'item-content',
									children: '企业级 UI 设计体系',
								},
							],
						},
					},
					{
						name: 'sub1',
						className: 'item-sub',
						children: {
							className: 'item-sub-item',
							children: [
								{
									name: 'image0',
									className: 'item-image',
									children:
										'https://gw.alipayobjects.com/zos/rmsportal/ruHbkzzMKShUpDYMEmHM.svg',
								},
								{
									name: 'title',
									className: 'item-title',
									children: 'Ant Design',
								},
								{
									name: 'content',
									className: 'item-content',
									children: '企业级 UI 设计体系',
								},
							],
						},
					},
				],
			},
			{
				name: 'item1',
				className: 'header3-item',
				children: {
					href: '#',
					children: [{ children: '导航二', name: 'text' }],
				},
			},
			{
				name: 'item2',
				className: 'header3-item',
				children: {
					href: '#',
					children: [{ children: '导航三', name: 'text' }],
				},
			},
			{
				name: 'item3',
				className: 'header3-item',
				children: {
					href: '#',
					children: [{ children: '导航四', name: 'text' }],
				},
			},
		],
	},
	mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner50DataSource = {
	wrapper: { className: 'home-page-wrapper banner5' },
	page: { className: 'home-page banner5-page' },
	childWrapper: {
		className: 'banner5-title-wrapper',
		children: [
			{ name: 'title', children: '产品名', className: 'banner5-title' },
			{
				name: 'explain',
				className: 'banner5-explain',
				children: '产品标语介绍',
			},
			{
				name: 'content',
				className: 'banner5-content',
				children: '产品的详细说明，如是什么东西之类的文字',
			},
			{
				name: 'button',
				className: 'banner5-button-wrapper',
				children: {
					href: '#',
					className: 'banner5-button',
					type: 'primary',
					children: '开始使用',
				},
			},
		],
	},
	image: {
		className: 'banner5-image',
		children:
			'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ',
	},
};
export const Content00DataSource = {
	wrapper: { className: 'home-page-wrapper content0-wrapper' },
	page: { className: 'home-page content0' },
	OverPack: { playScale: 0.3, className: '' },
	titleWrapper: {
		className: 'title-wrapper',
		children: [{ name: 'title', children: '产品与服务' }],
	},
	childWrapper: {
		className: 'content0-block-wrapper',
		children: [
			{
				name: 'block0',
				className: 'content0-block',
				md: 8,
				xs: 24,
				children: {
					className: 'content0-block-item',
					children: [
						{
							name: 'image',
							className: 'content0-block-icon',
							children:
								'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
						},
						{
							name: 'title',
							className: 'content0-block-title',
							children: '一站式业务接入',
						},
						{ name: 'content', children: '支付、结算、核算接入产品效率翻四倍' },
					],
				},
			},
			{
				name: 'block1',
				className: 'content0-block',
				md: 8,
				xs: 24,
				children: {
					className: 'content0-block-item',
					children: [
						{
							name: 'image',
							className: 'content0-block-icon',
							children:
								'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
						},
						{
							name: 'title',
							className: 'content0-block-title',
							children: '一站式事中风险监控',
						},
						{
							name: 'content',
							children: '在所有需求配置环节事前风险控制和质量控制能力',
						},
					],
				},
			},
			{
				name: 'block2',
				className: 'content0-block',
				md: 8,
				xs: 24,
				children: {
					className: 'content0-block-item',
					children: [
						{
							name: 'image',
							className: 'content0-block-icon',
							children:
								'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
						},
						{
							name: 'title',
							className: 'content0-block-title',
							children: '一站式数据运营',
						},
						{
							name: 'content',
							children: '沉淀产品接入效率和运营小二工作效率数据',
						},
					],
				},
			},
		],
	},
};
export const Content10DataSource = {
	wrapper: { className: 'home-page-wrapper content1-wrapper' },
	OverPack: { className: 'home-page content1', playScale: 0.3 },
	imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
	img: {
		children: 'https://zos.alipayobjects.com/rmsportal/nLzbeGQLPyBJoli.png',
	},
	textWrapper: { className: 'content1-text', md: 14, xs: 24 },
	title: { className: 'content1-title', children: '企业资源管理' },
	content: {
		className: 'content1-content',
		children:
			'云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
	},
};
export const Content30DataSource = {
	wrapper: { className: 'home-page-wrapper content3-wrapper' },
	page: { className: 'home-page content3' },
	OverPack: { playScale: 0.3 },
	titleWrapper: {
		className: 'title-wrapper',
		children: [
			{
				name: 'title',
				children: '蚂蚁金融云提供专业的服务',
				className: 'title-h1',
			},
			{
				name: 'content',
				className: 'title-content',
				children: '基于阿里云强大的基础资源',
			},
		],
	},
	block: {
		className: 'content3-block-wrapper',
		children: [
			{
				name: 'block0',
				className: 'content3-block',
				md: 8,
				xs: 24,
				children: {
					icon: {
						className: 'content3-icon',
						children: 'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
					},
					textWrapper: { className: 'content3-text' },
					title: { className: 'content3-title', children: '企业资源管理' },
					content: {
						className: 'content3-content',
						children: '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
					},
				},
			},
			{
				name: 'block1',
				className: 'content3-block',
				md: 8,
				xs: 24,
				children: {
					icon: {
						className: 'content3-icon',
						children: 'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
					},
					textWrapper: { className: 'content3-text' },
					title: { className: 'content3-title', children: '云安全' },
					content: {
						className: 'content3-content',
						children:
							'按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。',
					},
				},
			},
			{
				name: 'block2',
				className: 'content3-block',
				md: 8,
				xs: 24,
				children: {
					icon: {
						className: 'content3-icon',
						children: 'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
					},
					textWrapper: { className: 'content3-text' },
					title: { className: 'content3-title', children: '云监控' },
					content: {
						className: 'content3-content',
						children:
							'分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
					},
				},
			},
			{
				name: 'block3',
				className: 'content3-block',
				md: 8,
				xs: 24,
				children: {
					icon: {
						className: 'content3-icon',
						children: 'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
					},
					textWrapper: { className: 'content3-text' },
					title: { className: 'content3-title', children: '移动' },
					content: {
						className: 'content3-content',
						children:
							'一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。',
					},
				},
			},
			{
				name: 'block4',
				className: 'content3-block',
				md: 8,
				xs: 24,
				children: {
					icon: {
						className: 'content3-icon',
						children: 'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
					},
					textWrapper: { className: 'content3-text' },
					title: { className: 'content3-title', children: '分布式中间件' },
					content: {
						className: 'content3-content',
						children:
							'金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
					},
				},
			},
			{
				name: 'block5',
				className: 'content3-block',
				md: 8,
				xs: 24,
				children: {
					icon: {
						className: 'content3-icon',
						children: 'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
					},
					textWrapper: { className: 'content3-text' },
					title: { className: 'content3-title', children: '大数据' },
					content: {
						className: 'content3-content',
						children:
							'一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
					},
				},
			},
		],
	},
};
export const Footer00DataSource = {
	wrapper: { className: 'home-page-wrapper footer0-wrapper' },
	OverPack: { className: 'home-page footer0', playScale: 0.05 },
	copyright: {
		className: 'copyright',
		children: (
			<span>
				©2018 <a href="https://motion.ant.design">Ant Motion</a> All Rights Reserved
			</span>
		),
	},
};
