import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import { enquireScreen } from 'enquire-js';

import Layout from '../components/layout';
import SEO from '../components/seo';
// import { rhythm } from '../utils/typography';

import Nav from '../components/home/Nav';
import Banner from '../components/home/Banner';
import Content0 from '../components/home/Content0';
import Content1 from '../components/home/Content1';
import Content3 from '../components/home/Content3';
import Footer0 from '../components/home/Footer0';
import {
	Nav30DataSource,
	Banner50DataSource,
	Content00DataSource,
	Content10DataSource,
	Content30DataSource,
	Footer00DataSource,
} from '../components/home/homeDataSource';
import '../less/antMotionStyle.less';

let _isMobile;
enquireScreen((b) => {
	_isMobile = b;
});

const LandingPage = (props) => {
	const { data } = props;
	const siteTitle = data.site.siteMetadata.title;
	const [isMobile, setIsMobile] = useState(_isMobile);

	useEffect(() => {
		enquireScreen((b) => {
			setIsMobile(!!b);
		});
	}, []);
	const children = [
		<Nav id="Nav3_0" key="Nav3_0" dataSource={Nav30DataSource} isMobile={isMobile} />,
		<Banner
			id="Banner5_0"
			key="Banner5_0"
			dataSource={Banner50DataSource}
			isMobile={isMobile}
		/>,
		<Content0
			id="Content0_0"
			key="Content0_0"
			dataSource={Content00DataSource}
			isMobile={isMobile}
		/>,
		<Content1
			id="Content1_0"
			key="Content1_0"
			dataSource={Content10DataSource}
			isMobile={isMobile}
		/>,
		<Content3
			id="Content3_0"
			key="Content3_0"
			dataSource={Content30DataSource}
			isMobile={isMobile}
		/>,
		<Footer0
			id="Footer0_0"
			key="Footer0_0"
			dataSource={Footer00DataSource}
			isMobile={isMobile}
		/>,
	];
	return (
		<div className="templates-wrapper">
			<SEO title={siteTitle} keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
			{children}
		</div>
	);
	return (
		<Layout location={props.location} title={siteTitle}>
			<SEO title="All posts" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
		</Layout>
	);
};

export default LandingPage;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
